* {
  padding:0;
  margin:0;
}

.block1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-image: url('./bg.png');
  height: calc(80vh - 20px);
  background-position: center;
  object-fit: contain;
  background-size: cover;
  padding: 20px 0 0;
  flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.block2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-image: url('./bg2.png');
  background-position: center;
  object-fit: contain;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  color: white;
  gap: 10px;
  font-size: 24px;
  padding-top: 50px;
  min-height: 100vh;
}

.block1 img {
  width: 308px;
}

.block1 .button {
  border: 0;
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
    background: white;
    color: black;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
}

.inside {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  background-image: url('./man1.png');
  flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    margin-top: 20px;
}

.socialsBlock {
  margin-top: 20px;
  display: flex;
  gap: 16px;
}

.socialsBlock img {
  width: max-content;
}

.block2 {

}

@media only screen and (max-width : 768px) {
  .block2 img {
    width: 100%;
  }
}
